<template>
  <div
    v-if="
      getUserRoles() == Admin ||
      getUserRoles() == SuperAdmin ||
      getUserRoles() == DataEntry
    "
    class="d-flex justify-content-end align-items-center"
  >
    <b-button variant="primary" class="mx-1" @click="edit">تعديل</b-button>
   
    <b-button variant="outline-primary" class="mr-1" @click="exportToExcelAction">تصدير لملف اكسل </b-button>
   
    <b-button variant="outline-primary" @click="$router.go(-1)">تراجع </b-button>
    <b-button
      variant="outline-danger"
      class="ml-1"
      @click="removeCourse"
      >حذف</b-button
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry } from "@/router";
export default {
  computed: {
    ...mapState({
        DetailsPreviousExams: (state) =>
                state.previousExams.DetailsPreviousExams,    }),
  },
  data() {
    return {
      SuperAdmin,
      Admin,
      DataEntry,
    };
  },
  methods: {
    ...mapActions(["updatePreviouseExam", 
    "deletePreviousExam",
    "exportToExcel",
    "copyCourse"]),
    getUserRoles,
    removeCourse() {
 
        this.deletePreviousExam(this.DetailsPreviousExams.id);
    },
    edit() {
      this.updatePreviouseExam(this.DetailsPreviousExams);
    },
    exportToExcelAction() {
        console.log("this.DetailsPreviousExams");
        console.log(this.DetailsPreviousExams.name);

            this.exportToExcel(
                {
    id: this.DetailsPreviousExams.id,
    name: this.DetailsPreviousExams.name
}   
             );
    },
  },
};
</script>
